

.items{
  margin-bottom: 6vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 100%;
  p{
    color: rgba(245, 245, 245, 0.685);
  }
  .yokai-title{
   margin-bottom: 24px;
  }
}

.items-grow{
  transition: height .35s ease;
  height: 0;
  overflow: hidden;
  width: 70vw;
}

.items-row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color:white;
  gap:10px;


  &.show{

    //height: 200px;
  }

  .item{

    .item-container{
      position: relative;
    }
    .item-data{
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

    }

    .item-name{
      text-align: center;
    }
  }

  .item:hover {
    svg {
      opacity: 0.5;
    }

    img{
      opacity: 0.5;
    }

    .item-data {
      display: block;
    }
  }

  svg{
    height: 150px;
    width: 150px;
  }

  img{
    height: 150px;
    width: 150px;
  }
}

.item-categories{
  background: $color-main-dark;
  box-shadow: $shadow-dark;
  border-radius: 10px;
  width: 70vw;
  padding: 10px 25px;
  margin-bottom: 15px;
  margin-top: 15px;
  color:white;
  font-size: 16px;
  line-height: 19px;
}