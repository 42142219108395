.collection {
  p {
    text-align: center;
    color: rgba(245, 245, 245, 0.685);
    margin-bottom: 3vh;
  }
}
.gallery {
  //display: flex;
  //flex-direction: row;
  //flex-wrap: wrap;
  //justify-content: space-around;
  color: white;
  gap: 20px;

  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  justify-content: center;
  grid-gap: 25px;

  .yokai-container{
    > div:first-child {
      display: flex;
      flex-direction: column;
      img {
        height: 240px;
        width: 240px;
      }
    }
  }


  img {
    //height: 220px;
    &.active {
      border: 3px solid $color-main-pink;
      border-radius: 22px;
    }
  }

  span {
    margin: 5px 10px;
  }
}

.gallery-container {
  //display: flex;
  width: 76vw;
  background-color: $color-main-dark;
  box-shadow: $shadow-dark;
  border-radius: 6px;
  padding: 30px;
  margin: auto;
}

.details {
  position: fixed;
  right: 2%;
  color: white;
  display: flex;
  padding-left: 30px;
  flex-direction: row;
  flex-wrap: wrap;
  width: 13%;
  gap: 20px;
  height: 500px;

  .detail {
    height: 51px;
    width: 80px;

    background-color: #2c253d;
    box-shadow: 0 0 7px 6px rgba(44, 37, 61, 0.42);
    border-radius: 4px;

    div {
      padding: 5px;
    }
    div:first-child {
      color: white;

      height: 25px;
      font-size: 14px;
    }

    div:last-child {
      color: #d46ae5;
      height: 25px;
      font-size: 11px;
    }
  }

  > * {
    text-align: center;
  }
}
