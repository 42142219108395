.mint {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  margin-bottom: 30px;
  
  
  .mint-live{
    margin-top: 10px;
    background-color: #7a6c972d;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 100px;
  }

  .yokai-title {
    margin-bottom: 0;
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .viewer {
    height: 80vh;
    min-width: 500px;
    width: 50%;
    padding: 125px;
    border-radius: 25px;
    background-color: #2b2439;
    box-shadow: $shadow-dark;
    color: white;

    span {
      color: white;
      font-size: 30px;
      text-align: center;
    }

    img {
      width: 335px;
      height: 335px;
      //margin:25px
    }
  }

  .description {
    color: white;
    display: flex;
    gap: 26px;
    align-items: center;
    background: #410824;
    box-shadow: inset 3px 5px 4px rgba(0, 0, 0, 0.25); 
  
    padding-left: 16px;
    @media (max-width: 1200px) {
      padding-left: 0;
      padding-top: 10px;
    }

    .quantity {
      display: flex;
      //gap: 8px;

      .input-quantity {
        background: #B50D5E;
        margin-left: 10px;
        height: 28px;
        display: flex;

        > input {
          padding-left: 0px;
        }

        > .button {
          background: transparent;
          color: white;
          //font-weight: bold;
          font-size: 20px;
          width: 24px;
          height: 28px;
          border: none;
          display: inline-flex;
          img {
            vertical-align: middle;
            margin: auto;
          }
        }
      }
    }
  }

  .mint-button {
    width: 250px;
    height: 45px;
    color: white;
    background-color: #B50D5E;
    border-width: 0;
    font-size: 15px;
    //margin-top: 40px;
    gap: 25px;
  }
}

.minted {
  position: relative;
  background-color: $color-main-dark;
  align-self: center;
  box-shadow: $shadow-dark;
  border-radius: 6px;
  //min-height: calc(100vh - 250px);
  padding: 30px;
  justify-content: center;
  max-width: 76vw;
  min-height: 76vh;
  min-width: 76vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;


  p {
    font-size: 13px;
    color: #ffffff94;
  }

  .minted-inner {
    display: grid;
    grid-template-columns: repeat(auto-fill, 240px);
    justify-content: center;
    grid-gap: 25px;

    .minted-yokai-container, .yokais-preview {
      display: flex;
      flex-direction: column;
      color: white;
      gap: 6px;
      text-align: center;

      .yokais-preview {
        img:hover {
          transform: scale(0.94);
          cursor: pointer;
        }

        span {
          background-color: #4b0e2ccc;
          border-radius: 5px;
          padding: 5px;
        }
      }
    }
    .yokai-attributes{
      color: whitesmoke ;
      display: flex ;
      flex-wrap: wrap ;
      flex-direction: row ;
      gap:0.5vh ;
      padding: 0;
      padding-left: 1vw;
      justify-content: center ;
      .attributes {
  
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #460f29;
          height: fit-content;
          padding: 1vh;
          width: 140px;
          text-align: center;
      }
      .attribute{
          color: rgba(243, 200, 200, 0.63);
          font-size: 2vh;
      }
  }
  }
}

@media (max-width: 1200px) {
  .mint {
    flex-direction: column;
    gap: 30px;
  }
  .description {
    flex-direction: column !important;
  }
}

.minted-status{
  position: absolute;
  top: 1%;
  right:2%;
  text-align: right;
  
}