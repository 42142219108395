.sidebar {
  position: fixed;
  background: $sidebar-bg-color;
  overflow: hidden;
  transition: all 0.35s ease;
  z-index: 10;
  box-shadow: 10px 0px 36px -2px rgba(0, 0, 0, 0.4),4px 0px 13px 0px rgba(0, 0, 0, 0.25);

  &.toggled-down {
    @media (max-width: 600px) {
      transform: translate(-240px);
    }
  }
  .pro-sidebar-inner {
    background-color: transparent;
  }
  .pro-sidebar-header {
    border-bottom: none !important;
  }

  .pro-menu-item {
    height: 45px;
    > * {
      text-align: left;
      height: 45px;
      font-weight: 400;
      font-size: 13px;
      //line-height: 15px;
      color: white;
      padding: 0 35px 0 20px !important;
      line-height: 28px;

      img {
        vertical-align: middle;
        padding-right: 10px;
      }
    }
    &.active {
      background-color: rgba(255, 255, 255, 0.03);
      border-left: 3px solid #B50D5E;
      a {
        color: white;
      }
    }
  }
  li {
    i {
      color: #ff68e7;
    }
  }

  .footer {
    //bottom: 0;
    min-width: 240px;
    //position: fixed;
    margin-top: auto;

    .data {
      .yokai {
        border-radius: 4px;
        padding: 4px 10px;
      }
      .price {
        background-color: $color-main-dark;
      }
      font-size: 19px;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      span {
        font-size: 14px;
        color: rgba(255, 255, 255, 0.48);
      }
    }
    .media {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 26px;
      margin-bottom: 20px;
      gap: 25px;

      button {
        img {
          width: 18px;
          height: 14px;
          color: #4c3f69;
        }
        background-color: transparent;
        border: none;
      }
    }
  }
}

.sidebar-inner {
  background: transparent;
}

.pro-sidebar .pro-menu a {
  text-decoration: none;
  color: #ffffff !important;
}

.connect {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 35px;
  background-color: #B50D5E;
  color:whitesmoke;
  border: none;
  padding: 3px 23px;
  font-weight: 500;
  font-size: 14px;
}

.connected {
  display: flex;
  align-items: center;
  gap: 6px;
  height: 35px;
  background-color: transparent;
  color: #A21C5C;
  border: solid 1px rgba(173, 173, 173, 0.2);
  padding: 0px 23px;
  font-weight: 500;
  font-size: 14px;
}
