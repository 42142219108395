.modal-bg {
  position: fixed;
  top: 0%;
  left: 0%;
  height: 100vh;
  width: calc(100vw - 10px);
  background-color: #6110379c;
  backdrop-filter: blur(10px);
  z-index: 5;
}

.modal-item {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  left: 50%;
  padding-top: 2vh;
  margin-left: calc(-27vw + 120px);
  margin-top: -45vh;
  background-color: #0e1315;
  box-shadow: $shadow-dark;
  border-radius: 1.4vh;
  height: 90vh;
  width: 54vw;
  z-index: 3;
  a {
    margin-top: 3vh;
  }
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;

  .modal-yokai-nft {
    height: 36vh;
    margin-left: 4vh;
  }

  .dl-yokai {
    position: relative;
    height: 3vh;
    width: 3vh;
    background: none;
    background-color: transparent;
    cursor: pointer;
    opacity: 0.5;
  }
  .dl-yokai.hide{
    opacity: 1;
  }
  .dl-yokai:hover {
   opacity: 1;
  }
  .modal-title {
    color: whitesmoke;
    text-align: center;
    font-size: 3.5vh;
  }
}
.modal-img {
  position: fixed;
  top: 1vh;
  right: 0;
  width: calc(100% - 240px);
  opacity: 0.6;
}
.modal-img-bottom {
  position: fixed;
  bottom: 1vh;
  right: 0;
  width: calc(100% - 240px);
  opacity: 0.6;
}

.yokai-attributes {
  color: whitesmoke;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 0.5vh;
  padding-left: 1vw;
  font-size: 2vh;
  justify-content: center;
  .attributes {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #460f29;
    height: fit-content;
    padding: 1vh;
    width: 132px;
    text-align: center;
  }
  .attribute {
    color: rgba(243, 200, 200, 0.63);
    font-size: 1.8vh;
  }
}

@media (max-width: 600px) {
  .modal-img {
    width: 100%;
  }
  .modal-item {
    margin-left: -30vw;
    width: 60vw;
  }
}

.modal-yokai {
  display: flex;
  gap: 1vh;
}
.yokai-tool {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  margin-top: 1vh;
  margin-top: 28.7vh;
 
}
.yokai-tool-1{
    position: relative;
    height: 3vh;
}
.yokai-tool-1 .tooltip-text {
    display: none;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    top: -50%;
  }

.yokai-tool-1:hover .tooltip-text {
    display: inline;;
  }