@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-style: unset;
}

.mobile-only {
  @media (min-width: 600px) {
    display: none !important;
  }
}

.collapsable-toggler {
  display: flex;
  align-items: center;
  background: black;
  box-shadow: $shadow-dark;
  height: 8vh;
  padding: 10px;
  padding-left: 3vh;
  margin-top: 30px;
  color: white;
  font-size: 2.4vh;
}

.collapsable-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: white;
  padding-top: 9px;
}

.collapsable-transition {
  transition: height 0.35s ease;
  height: 0;
  overflow: hidden;
}

.desktop-only {
  @media (max-width: 600px) {
    display: none !important;
  }
}

html {
  max-width: 100%;
}

body {
  height: 100vh;
  background: radial-gradient(50% 50% at 56.44% 50%, #721140 0%, #490e2b 100%);
  background-attachment: fixed;
  font-family: "Titillium Web";
}

h1 {
  font-size: 4vw;
  font-weight: 400;
  color: whitesmoke;
  text-shadow: rgb(0 0 0 / 80%) 0.4px 6px 5px;
}

p {
  color: whitesmoke;
  text-shadow: rgb(0 0 0 / 60%) 0.4px 4px 4px;
}

button {
  font-family: "Roboto";
}
button:hover {
  cursor: pointer;
}
.yokai-container {
  gap: 6px;
  display: flex;
  flex-direction: column;
  >div{
    display: flex;
  }

  .yokai-name {
    background-color: #4b0e2ccc;
    border-radius: 5px;
    height: 34px;
    padding: 5px;
    text-align: center;
    img{
      display: none;
    }
    >*{
      margin:auto;
    }
  }
  .yokai-name:hover .name-hover {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    margin: auto;
    img{
      display: unset;

    }
  }

  > img {
    height: 230px;
    width: 230px;
    transition: all 0.5s ease-in-out;
    margin: auto;
    text-align: center;
  }

  .link-yokai {
    height: 25px;
    width: 90px;
  }

  .yokai-name:hover .name {
    display: none;
  }

  

  .yokai-name:hover {
    background-color: #bd256e7a;
    cursor: pointer;
  }
  .name-hover {
    display: none;
    color: whitesmoke;
    margin: auto;
  }
}

.flex {
  display: flex;
}
.row{
  display: flex;
  align-items: center;
  gap: 8px;
}

.gap {
  gap: 20px;
}
.wrap {
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

//Yokai Title Component Style---
.yokai-title {
  display: flex;
  font-size: 64px;
  background-color: rgba(27, 4, 4, 0.5);
  color: transparent;
  text-shadow: 3px 1px 0px #440725;
  background-clip: text;
  font-weight: 500;

  justify-content: center;

  @media (max-width: 600px) {
    font-size: 46px;
  }

  .yokai {
    font-weight: 500;
  }
}
.pro-sidebar {
  .yokai-title {
    font-size: 28px;
    margin: 16px;
    color: whitesmoke;
    justify-content: center;
  }
  .yokai-space {
    color: $color-main-pink;
    font-weight: 500;
  }
}
.mint-list {
  .yokai-title {
    font-size: 30px;
    margin-bottom: 0;
  }
}
.team-showcase .yokai-title {
  font-size: 23px;
  margin-bottom: 0;
}
//----------------------------

//-------Scroll Bar----------
::-webkit-scrollbar-track {
  background: $color-main-dark;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  background: $color-pink-light;
  border-radius: 50px;
}
//----------------------------

//---------Buttons------------
.main-button {
  padding-left: 20px;
  padding-right: 20px;
  height: 45px;
  color: white;
  background-color: $color-main-pink;
  border-width: 0;
  font-size: 16px;
  gap: 10px;
  box-shadow: 3px 6px 2px -1px rgba(0, 0, 0, 0.25);
}

.main-button.discord:hover {
  background-color: #610f38;
  box-shadow: inset 4px 6px 4px rgba(0, 0, 0, 0.25);
}

.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  .questionlist {
    width: 60%;
  }
  .yokai-title {
    margin-bottom: 0;
  }
}
