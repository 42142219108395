
.overview {
  margin-bottom: 6vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  max-width: 100%;
  h1 {
    text-align: center;
    font-size: 5.2vh;
  }

}

.overview-title {
  color: #fff;
  // text-shadow: 0 0 10px rgba(255, 255, 255, 0.685);
  margin-bottom:10px ;
  
}


.overview-presentation {
  display: flex;
  flex-direction: column;
  margin-top: 7vh;
  align-items: center;
  gap: 3vh;
}
.overview-hero {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  text-align: center;
  padding-top: 8%;
  padding-bottom: 14%;

  p {
    font-size: 21px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.904);
    font-style: normal;
    font-weight: 300;
  }

  .join {
    &:hover {
      text-decoration: underline solid 2px;
    }
  }
}


video{
  height: 80vh;

}
.features{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6vh;
  align-items: center;
  width: 100%;
  padding-top: 2%;
  padding-bottom: 2%;
  h1{
    margin-top: 6vh;
  }


}

.feature-list {
  display: flex;
  gap: 3.1vh;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 70vw;
}

.feature {
  background-color: $color-main-dark;
  box-shadow: $shadow-dark;
  display: flex;
  width: 42vh;
  flex-direction: column;
  align-items: center;
  font-size: 3vh;
  padding: 16px ;
  text-align: center;
  color: rgba(255, 255, 255, 0.938);
  span{
    i{
      text-shadow: 0 0 10px hsl(0deg 0% 100% / 80%);
    }
     
    p{
      margin-top: 10px;
    }
  }

  p{
    font-size: 2vh;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.705);
    height: 10vh;
  }

  img{
    height: 8vh;
  }
}


.roadmap-list{
  display: flex;
  flex-direction: column;
  gap: 3vh;
  .feature{
    width: 70vh;
    padding: 4%;
    font-size: 3vh;
  }
  a{
    pointer-events: none;
    margin:3vh
  }
}
.map{
  position: relative;
  text-shadow: rgba(48, 1, 1, 0.836) 5px 0px 5px;
  background-color: #460f29;
  box-shadow: inset 0px 0px 20px 4px rgba(0, 0, 0, 0.2),inset 2px 4px 6px rgba(0, 0, 0, 0.27); ;
  display: flex;
  border-radius: 1vh;
  padding-left: 6vh;
  padding-right: 6vh;
  width: 80vh;
  height: 32vh;
  font-size: 4vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px ;
  text-align: center;
  color: rgba(255, 255, 255, 0.938);
  span{
    padding-left: 2vh;
    padding-right: 2vh;
    i{
      font-size: 4vh;
      text-shadow: 0 0 10px hsl(0deg 0% 100% / 69%);
    }
    p{
      margin-top: 1vh;
      font-size: 2.1vh;
      font-weight: 300;
      color: rgba(255, 255, 255, 0.705);
      height: 10vh;
      
  }
    font-size: 3vh;
  }
  img{
    height: 8vh;
    opacity: 0.9;
  }
  a{
    color: white;
    text-decoration: underline;

  }
}

@media (max-width: 600px) {
  .map{
    width: 78vw;
    height: 30vw;
    span{
      i{
      font-size: 3vh ;
    }
    }
    p{
      display: none;
    }
  }
 
 
}


.title {
  font-size: 80px;
  color: white;
  font-weight: 300;
  margin-bottom: 40px;
  .fat {
    font-weight: 500;
  }
  .apo {
    color: #ff68e7;
    font-weight: 500;
  }
}

.overview-buttons {
  display: flex;
  gap: 30px;
  align-items: center;
}

.main-button.discord{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 233px;
  background-color: $color-pink-light;
}

.overview-img {
  width: 72vw;
  max-width: 1400px;
}
.chainlink {
  width: 45vw;
}

@media (max-width: 1000px) {
  .overview-img {
    display: none;
  }
  .team-showcase {
    flex-direction: column;
    .description {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      height: 100%;
      gap: 10px;
    }
    //justify-content: center;
    > div {
      margin: auto;
    }
  }
}

.overview-medias {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  margin-bottom: 15vh;

  img {
    margin-right: 10px;
  }
 
  .medias-link{
   display: flex;
   width: 70vw;
   justify-content: space-around;
  }

  a,
  button {
    display: flex;
    align-items: center;
    border: $color-main-dark;
    box-shadow: $shadow-dark;
    box-sizing: border-box;
    border-radius: 50px;
    background-color: $color-main-dark;
    font-size: 16px;
    color: #b19fdb;
    padding: 8px 16px;

    &:hover {
      background-color: #d592f009;
    }
  }
}

.discover{
  position: absolute;
  bottom: 4%;
  height: 3vh;
}
.discover.see{
  bottom: 10%;
  color: whitesmoke;
  opacity: 0.5  ;
}

.next{
  height: 3vh;
}
.yokaigif{
  margin-left: 2vh;
  width: 31vh;
  height: 43vh;
  box-shadow: $shadow-dark;
}
.yokaieyes{
 width: 32vw;
 margin-bottom: 20px;
}

@media (max-width: 600px) {
  .overview-buttons {
    flex-direction: column;
    
  }
  .yokai-intro {
    flex-direction: column;
    align-items: center;
  }
}

.yokai-intro{
  display: flex;
  gap: 2vw;
  align-items: center;
  margin-top: 6vh;
  padding-left: 6%;
  padding-right: 4%;
}


.intro{
padding: 2%;
border-radius: 10px;
font-weight: 300;
font-size: 2.9vh;
color: #fcbada;
font-family: 'Roboto';

i{
  color: rgb(255, 255, 255);
 font-weight: 400;
}

}

.phase{
  width: 100%;
  background-color: transparent;
  box-shadow: $shadow-dark;
  height: 20vh;
}



.eyes{
 height: 4.2vh;
}

.teaser{
  color: #410824;
  text-shadow: none;
  font-size: 3.2vh !important;
  font-weight: 600;
}

.spin{
  position: absolute;
  opacity: 0.9;
  margin-bottom: 4%;
  height: 76vh;
  z-index: -1;
}
@media (max-width: 600px) {
  .spin{
    height: 90vw;
  }
  
}


.section-line{
  border-bottom: solid 1px #410824;
}
.transition{
    margin: 5%;
    height: 19vh;
}



.bamboo{
  z-index: -1 ;
  width: 70vw;
  position: absolute;
  left: 50%;
  margin-left: -35vw;
}
.texture{
  position: absolute;
  z-index: -2;
  opacity: 0.5;
  width: 100%;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 11%;
  width: 100%;
  
  h1{
    margin-bottom: 3vh;
  }
}
@media (min-width: 600px) {
  .team{
  background-image: url(../../media/texture1.svg);
  }
}


.team-showcase {
  display: flex;
  margin-bottom: 5vh;
  gap: 8vh;

  .member{
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .team-avatar {
    height: 30vh;
    width: 30vh;
    box-shadow: $shadow-dark;
    border-radius: 2vh;
  }
  .description {
    display: flex;
    gap: 1vh;
    align-items: center;
    justify-content: space-between;
    margin-top: 1vh;
    width: 100%;
    .name {
      font-size: 2.1vh;
      font-weight: 300;
      width: 100%;
      text-align: center;
      color: white;
      padding: 1%;
      padding-left: 3%;
      padding-right: 3%;
      background-color: #410824;
      box-shadow: inset 6px 7px 4px rgba(0, 0, 0, 0.27);
      border-radius: 1vh;
      margin-bottom: 1.5vh;
    }
    
  }

}
.social{
  img{
    height: 3vh;  
    margin-right: 2vh;
  }
}
.sections {
  width: 100%;
  position: absolute;
  z-index: -1;
}

.paintswap-link{
  display: flex;
  align-items: center;
  background: none;
  box-shadow: none;
  img{
    height: 23px;
  }
}
