.container{
 position: relative;
    @media (min-width: 600px) {
        margin-left: 240px;
    }

}
.bg-illustration{
    position: absolute;
    width: calc(100vw - 250px);
    overflow: hidden;
    z-index: 2;
    pointer-events: none;
    @media (max-width: 600px) {
      width: 100%;
    }
  }
  .bg-illustration.bottom{
    position: fixed;
    bottom: 0;
    z-index: 2;
  }
  
 
.inner{
    display: flex;
    flex-direction: column;

}

.bloodmoon{
    position: absolute;
    display: none;
    height: 8vw;
    top: 50%;
    left: 50%;
    margin-top: -260px;
    margin-left: -80px; 
    z-index: -1;
}