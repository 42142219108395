.header{
  position: relative;
  height: 65px;
  width: 100%;
  padding: 15px;
  background-color: transparent;
  display: flex;
  align-content: center;
  justify-content: space-between;
  z-index: 100;
  img{
    height: 30px;
    width: 30px;
  }
}

