
.ecosystem-list{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6vh;

}

.ecosystem-item{
    height: 30vh;
    width: 70vw;
    background: $color-main-dark;
    border-radius: 1vh;
    box-shadow: $shadow-dark;
}